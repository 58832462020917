// Add your custom JS here.
jQuery('#primary').addClass('container py-5 pt-md-7 pb-md-8');
jQuery('.archive .type-product').removeClass('product');


//Mega Menu
jQuery(document).ready(function (jQuery) {

  //const mq = window.matchMedia( "(min-width: 992px)" ); 
  //if (mq.matches) {
  // window width is at least 500px
    const wrapNavigationMenus = document.querySelectorAll("#main-menu > li");

    /* Create new structure with the current and new elements */

    wrapNavigationMenus.forEach((menu) => {
      const listElements = menu.querySelectorAll(
        ".dropdown-toggle + ul.dropdown-menu > li"
      );

      const wrapper = document.createElement("div");
      wrapper.classList.add("navigation");
      const ulWrapper = menu.querySelector(".dropdown-toggle + ul.dropdown-menu");

      if (ulWrapper) {
        const detailsContainer = document.createElement("div");
        detailsContainer.classList.add("details-container");

        const mainMenuTitle = document.createElement("div");
        mainMenuTitle.classList.add("main-menu-title");

        const mainMenuLink = document.createElement("a");
        mainMenuTitle.appendChild(mainMenuLink);

        const mainMenuDescription = document.createElement("div");
        mainMenuDescription.classList.add("main-menu-description");

        const openMenuThirdColumn = document.createElement("div");
        openMenuThirdColumn.classList.add("open-menu-third-column");

        detailsContainer.appendChild(mainMenuTitle);
        detailsContainer.appendChild(mainMenuDescription);

        ulWrapper.appendChild(detailsContainer);
        ulWrapper.appendChild(openMenuThirdColumn);
        ulWrapper.appendChild(wrapper);
      }

      listElements.forEach((listElement) => {
        wrapper.appendChild(listElement);
      });
    });

    const allMenuItems = document.querySelectorAll(".navigation > .nav-item");
    const hoverMenus = document.querySelectorAll(
      ".navigation > .nav-item.menu-item-has-children"
    );

    const allNavigationColumns = document.querySelectorAll(
      "#navbarNavDropdown .navigation"
    );

    /* Classify two types of menu variations */

    allNavigationColumns.forEach((item) => {
      const navigationDropdown = item.querySelector("ul.dropdown-menu");
      if (navigationDropdown) {
        item.closest("li.menu-item").classList.add("menu-variation-1");
      } else {
        item.closest("li.menu-item").classList.add("menu-variation-2");
      }
    });

    const allMenuDescriptions = document.querySelectorAll(
      ".menu-variation-2 li.menu-item"
    );

    allMenuDescriptions.forEach((el) => {
      let menuDescription = document.createElement("p");
      menuDescription.classList.add("menu-description");
      el.appendChild(menuDescription);
    });

    function hideOtherGrandChildMenus() {
      allMenuItems.forEach((hoverEl) => {
        if (hoverEl.classList.contains("menu-item-has-children")) {
          hoverEl.querySelector("ul").classList.remove("show");
        }
      });
    }

    function resetThirdColumn() {
      hoverMenus.forEach((el) => {
        el.querySelector("ul").classList.remove("show");
      });
    }

    const navigationElements = document.querySelectorAll(".navigation > li");

    navigationElements.forEach((el) => {
      el.addEventListener("mouseover", () => {
        resetThirdColumn();
      });
    });

    /* Show the grandchild items on hover */
    
    hoverMenus.forEach((hoverEl) => {
      hoverEl.addEventListener("mouseover", (el) => {
        hideOtherGrandChildMenus();
        let grandChildMenuToShow = el.currentTarget.querySelector("ul");
        grandChildMenuToShow.classList.add("show");
      });
    });

    /* Set the main menu title */

    const allMenusWithChildren = document.querySelectorAll(
      "#main-menu > .menu-item-has-children"
    );

    let menuData = [];
    let menuDataToFilter = [];

    let pageData = [];

    /* Set the main menu title */

    allMenusWithChildren.forEach((menu, index) => {
      const number = menu.id.split("-");

      menuDataToFilter.push(number[number.length - 1]);

      allMenusWithChildren[index].querySelector(
        ".details-container .main-menu-title a"
      ).innerText = allMenusWithChildren[index]
        .querySelector("a")
        .getAttribute("title");
    });

    // Set the main menu descriptions by fetching page excerpts

    fetch("/wp-json/wp/v2/navigation/2")
      .then((res) => res.json())
      .then((data) => {
        menuData = data;

        for (let i = 0; i < menuData.length; i++) {
            for (let j = 0; j < menuDataToFilter.length; j++) {
            if (menuData[i].ID == menuDataToFilter[j]) {
              pageData.push(Number(menuData[i].object_id));
            }
          }
        }

        fetchPageData(pageData);
      });

    let pageExcerpts = [];

    function fetchPageData(data) {
      let urls = [];
      data.forEach((page, index) => {
        urls.push(`/wp-json/wp/v2/pages/${page}`);
      });

      // Maps each URL into a fetch() Promise
      var requests = urls.map(function (url) {
        return fetch(url).then(function (response) {
          return response.json();
        });
      });

      // Resolve all the promises
      Promise.all(requests)
        .then((results) => {
          return results;
        })
        .then((data) => {
          data.forEach((result, index) => {
            pageExcerpts.push({ page: page, data: result.excerpt.rendered, link: result.link });
            if (index >= data.length - 1) {
              renderExcerpts(pageExcerpts);
            }
          });
        });
    }

    function renderExcerpts(excerpts) {
      excerpts.forEach((excerpt, index) => {
        allMenusWithChildren[index].querySelector(
          ".main-menu-description"
        ).innerHTML = excerpt.data;
      });

      excerpts.forEach((excerpt, index) => {
        allMenusWithChildren[index].querySelector(
          ".main-menu-title a"
          //".details-container a"
        ).setAttribute("href", excerpt.link);
      });
      subMenuSetup();
    }

    /* Set the sub menu descriptions */

    function subMenuSetup() {
      let subMenuDataToFilter = [];

      const allSubMenusWithChildren = document.querySelectorAll(
        "#main-menu .menu-variation-2 .navigation li.menu-item-object-page"
      );

      allSubMenusWithChildren.forEach((menu) => {
        const number = menu.id.split("-");
        subMenuDataToFilter.push(number[number.length - 1]);
      });

      let subPageData = [];

      for (let i = 0; i < menuData.length; i++) {
        for (let j = 0; j < subMenuDataToFilter.length; j++) {
          if (menuData[i].ID == subMenuDataToFilter[j]) {
            subPageData.push(Number(menuData[i].object_id));
          }
        }
      }

      subPageExcerptsFetch(subPageData);

      let subPageExcerpts = [];

      function subPageExcerptsFetch(data) {
        let subPageurls = [];
        data.forEach((page, index) => {
          subPageurls.push(`/wp-json/wp/v2/pages/${page}`);
        });

        // Maps each URL into a fetch() Promise
        var requests = subPageurls.map(function (url) {
          return fetch(url).then(function (response) {
            return response.json();
          });
        });

        // Resolve all the promises
        Promise.all(requests)
          .then((results) => {
            return results;
          })
          .then((data) => {
            data.forEach((result, index) => {
              subPageExcerpts.push({ page: page, data: result.excerpt.rendered });
              if (index >= data.length - 1) {
                renderSubPageExcerpts(subPageExcerpts);
              }
            });
          });
      }

      function renderSubPageExcerpts(data) {
        data.forEach((excerpt, index) => {
          allSubMenusWithChildren[index].querySelector(
            ".menu-description"
          ).innerHTML = excerpt.data;
        });
      }
    }
  //} else {
    // window width is less than 500px
  //}
});


jQuery(document).ready(function(){
// Append content
jQuery(".menu-item-20506 .main-menu-description").append('Here you can buy a selection of Austrian scythes, blades, sharpening stones and peening equipment.');
jQuery(".menu-item-20511 .main-menu-description").append('A course is a great way to start using a scythe or to improve on your existing skills.');
jQuery(".menu-item-20521 .menu-description").append('The aim of this course is to teach you what you need to know to get you mowing. It is an ideal beginning before you buy your first scythe or to improve your technique if your already have one.');
jQuery(".menu-item-20522 .menu-description").append('This is a day long workshop to help you really get to grips with the art of sharpening and peening your blade. Knowing how to get your blade really sharp can make a lot of difference to the ease and quality of your mowing.');
});


//jQuery( document ).ready( function( $ ) {
  // $() will work as an alias for jQuery() inside of this function
  // [ your code goes here ]
//} );